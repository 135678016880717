<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div class="uk-card uk-card-default uk-margin">
      <div class="uk-modal-body">
        <label class="uk-form-label">Gender</label>
        <div class="uk-width-1-1 uk-inline">
            <multiselect 
                v-model="gender_value"
                placeholder="Search or add gender"
                label="name"
                track-by="name"
                :options="gender_options"
                :multiple="true"
                @tag="addGenderOption">
            </multiselect>
        </div>
        <label class="uk-form-label">Education</label>
        <div class="uk-width-1-1 uk-inline">
            <multiselect 
                v-model="education_value"
                placeholder="Search or add education"
                label="name"
                track-by="name"
                :options="education_options"
                :multiple="true"
                @tag="addEducationOption">
            </multiselect>
        </div>
        <label class="uk-form-label">Job Type</label>
        <div class="uk-width-1-1 uk-inline">
            <multiselect 
                v-model="job_type_value"
                placeholder="Search or add job type"
                label="name"
                track-by="name"
                :options="job_type_options"
                :multiple="true"
                @tag="addJobTypeOption">
            </multiselect>
        </div>
        <label class="uk-form-label">Industry</label>
        <div class="uk-width-1-1 uk-inline">
            <multiselect 
                v-model="industry_value"
                placeholder="Search or add industry"
                label="name"
                track-by="name"
                :options="industry_options"
                :multiple="true"
                @tag="addIndustryOption">
            </multiselect>
        </div>
        <br />
        <br />
        <label class="uk-form-label">Email</label>
        <div class="uk-width-1-1 uk-inline">
            <input 
                class="uk-input"
                type="text" 
                v-model="post_detail.email" 
                placeholder="input email"
            >
        </div>
        <label class="uk-form-label">Phone</label>
        <div class="uk-width-1-1 uk-inline">
            <input
                class="uk-input"
                type="text"
                v-model="post_detail.phone"
                placeholder="input phone"
            >
        </div>
        <label class="uk-form-label">Address</label>
        <div class="uk-width-1-1 uk-inline">
            <input
                class="uk-input"
                type="text"
                v-model="post_detail.city"
                placeholder="input city"
            >
        </div>
        <label class="uk-form-label">Skills</label>
        <div class="uk-width-1-1 uk-inline">
            <input
                class="uk-input"
                type="text"
                v-model="post_detail.skills"
                placeholder="input skills"
            >
        </div>
        <label class="uk-form-label">Company</label>
        <div class="uk-width-1-1 uk-inline">
            <input
                class="uk-input"
                type="text"
                v-model="post_detail.company"
                placeholder="input company"
            >
        </div>
        <label class="uk-form-label">Title</label>
        <div class="uk-width-1-1 uk-inline">
            <input
                class="uk-input"
                type="text"
                v-model="post_detail.title"
                placeholder="input title"
            >
        </div>
        <label class="uk-form-label">Caption</label>
        <div class="uk-width-1-1 uk-inline">
            <textarea
                class="uk-input"
                v-model="post_detail.caption"
                placeholder="input caprion"
            ></textarea>
        </div>
        <label class="uk-form-label">Image</label>
          <input
            name="post_image"
            type="file"
            ref="post_image"
            class="uk-input"
            placeholder="Upload Background">
      </div>
      <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-default uk-margin-right" type="button" @click="toBack" :disabled="isLoading">Back</button>
          <button v-if="isLoading" class="uk-button uk-button-default" type="button" disabled><div uk-spinner></div></button>
          <button v-else class="uk-button uk-button-default" type="button" @click="saveEditModal">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            isLoading: false,
            post_detail: {
                gender: [],
                education: [],
                job_type: [],
                email: '',
                phone: '',
                city: '',
                skills: '',
                company: '',
                title: '',
                caption: '',
                image: ''
            },
            education_value: [],
            education_options: [
                { name: 'sma/smk'},
                { name: 'diploma/d3'},
                { name: 'sarjana/s1'},
                { name: 'master/s2'},
                { name: 'apa saja'}
            ],
            gender_value: [],
            gender_options: [
                { name: 'laki-laki'},
                { name: 'perempuan'}
            ],
            job_type_value: [],
            job_type_options: [
                { name: 'full-time'},
                { name: 'contract'},
                { name: 'part-time'},
                { name: 'magang'},
                { name: 'freelance'}
            ],
            industry_value: [],
            industry_options: [
                { name: 'hotel'},
                { name: 'restoran/cafe'},
                { name: 'toko/retail'}
            ]
        };
    },
    methods: {
        ...mapActions({
            createPost: 'post/createPost',
            uploadImageApi: 'post/uploadImage'
        }),
        addEducationOption (newTag) {
            const tag = {
                name: newTag
            };
            this.education_options.push(tag);
            this.education_value.push(tag);
        },
        addGenderOption (newTag) {
            const tag = {
                name: newTag
            };
            this.gender_options.push(tag);
            this.gender_value.push(tag);
        },
        addJobTypeOption (newTag) {
            const tag = {
                name: newTag
            };
            this.job_type_options.push(tag);
            this.job_type_value.push(tag);
        },
        addIndustryOption (newTag) {
            const tag = {
                name: newTag
            };
            this.industry_options.push(tag);
            this.industry_value.push(tag);
        },
        async saveEditModal() {
            this.isLoading = true;
            this.post_detail = {
                ...this.post_detail,
                gender: this.gender_value.map(dat => dat.name),
                education: this.education_value.map(dat => dat.name),
                job_type: this.job_type_value.map(dat => dat.name),
                industry: this.industry_value.map(dat => dat.name),
            };
            
            await this.uploadImage();
            try {
                const response = await this.createPost(this.post_detail);
                if (response.status === 'OK') {
                    this.isLoading = false;
                    this.$router.push('/admin/job-combine');
                }
            } catch (err) {
                console.error(err);
            }
        },
        async uploadImage() {
            var files = this.$refs.post_image.files;
            let formData = new FormData();

            for( var i = 0; i < files.length; i++) {
                let file = files[i];
                formData.set('file', file, file.fileName);
                const result = await this.uploadImageApi({ folder: 'insta-image', data: formData });
                this.post_detail.image = result.file_url;
            }
        },
        toBack() {
            window.location.href = '/admin/job-combine';
        },
    }
};
</script>
